<template>
  <div
    class="tw-bg-main d-flex justify-content-center align-items-center"
    style="height: 100vh"
  >
    <p class="p-3 alert-danger d-inline-block fw-bolder">
      (Line)請稍後~正在為您轉址中...
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      identity: 'customer',
      loading: false,
      lineCallBackInfo: {
        code: "",
        state: "",
      },
    };
  },
  created() {
    this.identity = window.localStorage.getItem('identity') ? window.localStorage.getItem('identity') : 'customer'
    this.loading = true;
    this.dealWithLineCallBack();
    this.lineLogin();
  },
  methods: {
    // 處理 line 登入完回傳的資訊
    dealWithLineCallBack() {
      const array = window.location.search.split("&");
      array[0] = array[0].slice(1);
      // 這邊網頁板登入 query 只會有 2 個，但手機版登入會經過 APP 登入所以會有 4 個 query
      array.forEach((item) => {
        console.log(item);
        if (item.split("=")[0] === "code") {
          this.lineCallBackInfo.code = item.split("=")[1];
        } else if (item.split("=")[0] === "state") {
          this.lineCallBackInfo.state = item.split("=")[1];
        }
      });
    },
    // 執行 line 登入的 API
    lineLogin() {
      const vm = this
      const lineLoginAPI = `${process.env.VUE_APP_API}/apps/line/loginCallback`;
      const data = new FormData();
      data.append("code", this.lineCallBackInfo.code);
      data.append("state", this.lineCallBackInfo.state);
      $.ajax({
        type: 'POST',
        async: true,
        url: lineLoginAPI,
        data: data,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const token = res.data;
            vm.$methods.delCookie("serverToken");
            vm.$methods.setCookie("serverToken", token)
            vm.go()
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          alert(err.responseJSON.message);
          if (vm.lineCallBackInfo.state === 'login') {
            if (vm.identity === 'seller') window.location.href = `${process.env.VUE_APP_ORIGINURL}/sellerLogin`;
            else window.location.href = `${process.env.VUE_APP_ORIGINURL}/customerLogin`;
          } else {
            if (vm.identity === 'seller') window.location.href = window.location.href = `${process.env.VUE_APP_ORIGINURL}/user/linkAccounts`;
            else window.location.href = window.location.href = `${process.env.VUE_APP_ORIGINURL}/customer/storeLists`;
          }
        },
      });
    },
    // 前往
    go() {
      this.$router.push(this.$methods.go('line'))
    },
  },
};
</script>